// Required during insertion if we have not set the keys it will add to the payload
export const insertDefaultPayLoad = {
  email: '',
  name: '',

  deleted: false,
}

export const queryClients = `query users($query: UserQueryInput, $limit: Int, $sortBy: UserSortByInput){
    users(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
      id
    }
  }
  `

// eslint-disable-next-line no-underscore-dangle
export const _getClients = `query ClientsData($input: ClientsDatum!){
    ClientsData(input: $input) {
      totalRecords
      clients {
        _id
        custom_data {
          project
          role
          username
        }
        data {
          email
        }
        id
      }
    }
  }`

export const queryClient = `query user($query: UserQueryInput){
    user(query: $query) {
      _id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
      id
    }
  }
  `

export const addClient = `mutation insertOneUser($data: UserInsertInput!){
    insertOneUser(data: $data) {
      _id
      id
      custom_data {
        project
        role
        username
      }
      data {
        email
      }
    }
  }`

export const updateClient = `mutation updateOneUser($query: UserQueryInput!, $data: UserUpdateInput!) {
  updateOneUser(query: $query, set: $data) {
    _id
  }
}
`

export const deleteOneClient = `mutation deleteOneUser($query: UserQueryInput!) {
  deleteOneUser(query: $query) {
    _id
  }
}
`
export const checkIfClientExistsInUser = `query getClientById($query: UserQueryInput!) {
  user(query: $query) {
    _id
    custom_data {
      project
      role
    }
    id
  }
}`

export const getClient = `query client($query: ClientQueryInput){
  client(query: $query) {
    _id
    publicId
    compliance_calculation_fixed
    daily_dialogue_image_required
    default_language
    different_domains
    zone_agency_filter
    daily_dialogue_date_disabled
    commitment_functionality
    new_confirmation_date_variable
    default_view_pc_team
    compliance_calculation_by_role
    default_view_daily_dialogue
    exclude_pc_functionality
    add_improvement_in_confirmation
    exclude_pc_team_module
    positive_happiness_index
    excludedPages
    homePagePath
    complaintRecipients {
      economicCrimesLaw {
        main
        substitute
      }
      karinLaw {
        main
        substitute
      }
    }
  }
}
`

export const updateOneClient = `mutation updateOneClient($query: ClientQueryInput, $data: ClientUpdateInput!) {
  updateOneClient(query: $query, set: $data) {
    _id
  }
}
`